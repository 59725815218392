const badgeMapping = {
  "Medical & Stiintific" : "medical",
  "Birouri/Comercial": "comercial",
  "Cladiri rezidentiale private" : "rezidential",
  "Infrastructura" : "infrastructura",
  "Constructii sociale si Comunitate" : "comunitate",
  Industrial: "industrial",
  "Utilitati" : "utilitati",
  "Retail" : "retail",
  "Hotel & Relaxare" : "hotel"
};

export const computeBadge = (topItem, topRegional) => {
  let badgePrefix =
    topItem.pozitie == 1
      ? "1"
      : topItem.pozitie <= 10
      ? "10"
      : topItem.pozitie <= 100
      ? "100"
      : topItem.pozitie <= 1000
      ? "1000"
      : "";
  let badgeName = badgeMapping[topItem.domeniu];
  let badgePositionName = topItem.contactPosition == 1 ? "p" : "ag";
  let badgeRegionName = topRegional ? "judetean" : "national";

  console.log(
    `/img/topuri/${badgePrefix}_${badgeName}_${badgePositionName}_${badgeRegionName}.svg`
  );

  return badgeName
    ? `/img/topuri/${badgePrefix}_${badgeName}_${badgePositionName}_${badgeRegionName}.svg`
    : null;
};
