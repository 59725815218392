import React, { useState, useEffect } from "react";
import "./theme.css";
import "./CompanyDetails.css";

import { PushSpinner } from "react-spinners-kit";

import { useStoreActions, useStoreState } from "easy-peasy";

import TipuriLucrariTable from "./segmentare/TipuriLucrariTable";
import SegmentareDomeniiChart from "./segmentare/SegmentareDomeniiChart";
import EvolutieCifraAfacereChart from "./segmentare/EvolutieCifraAfacereChart";
import EvolutieProfitChart from "./segmentare/EvolutieProfitChart";
import SideBar from "./sidebar/SideBar";
import NetworkingFirmeGraph from "./segmentare/NetworkingFirmeGraph";
import DateFinanciareBanner from "./dateFinanciare/DateFinanciareBanner";
import ListaProiecte from "./listaProiecte/ListaProiecteTable";
import ArticolePresa from "./articolePresa/ArticolePresa";
import ListaOameniCheie from "./oameniCheie/ListaOameniCheie";
import EvolutieNumarAngajatiChart from "./segmentare/EvolutieNumarAngajatiChart";
import TendinteNoutatiTable from "./tendinteNoutati/TendinteNoutatiTable";
import InformatiiAdminsitrativeTable from "./informatiiAdministrative/InformatiiAdminsitrativeTable";
import SegmentareDomeniiPeJudete from "./segmentare/SegmentarePeJudet";
import SegmentareDomeniiMobileChart from "./segmentare/SegmentareDomeniiMobileChart";
import TopuriCompanie from "./topuri/TopuriCompanie";

export function CompanyDetails(props) {
  let [administratori, setAdministratori] = useState([]);

  let segmentareProiectePerFinantare = useStoreState((state) => state.companyProfile.companyProfile.proiecteDupaFinantare);

  let [asociati, setAsociati] = useState([]);

  const getCompanyProfile = useStoreActions(
    actions => actions.companyProfile.getCompanyProfile
  );

  const companyProfile = useStoreState(
    state => state.companyProfile.companyProfile
  );

  let seoCompetitors = useStoreState(
    state => state.companyProfile.companyProfile.seoCompetitors
  );

  const oameniCheie = useStoreState(
    state => state.companyProfile.companyProfile.oameniCheie
  );

  const companyProfileError = useStoreState(
    state => state.companyProfile.companyProfileError
  );
  const companyProfileLoaded = useStoreState(
    state => state.companyProfile.companyProfileLoaded
  );

  const articole = useStoreState(
    state => state.companyProfile.companyProfile.articole
  );

  const topuri = useStoreState(
    state => state.companyProfile.companyProfile.topStatistici
  );

  let lastYear = new Date().getFullYear() - 1;

  useEffect(() => {
    let searchParams = new URLSearchParams(props.location.search);
    getCompanyProfile(searchParams.get("p"));
  }, []);

  useEffect(() => {
    if (oameniCheie && oameniCheie.length) {
      const _administratori = oameniCheie.filter(item => item.rol == "ADMIN");
      const _asociati = oameniCheie.filter(item => item.rol == "ASOCIAT");

      setAdministratori(_administratori);
      setAsociati(_asociati);
    }
  }, [oameniCheie]);

  return companyProfileError ? (
    <div className="main-profile-content">
      <div className="container-fluid h-100">
        <h1>Profilul de companie nu exista</h1>
      </div>
    </div>
  ) : companyProfileLoaded ? (
    <div className="main-profile-content">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="fixed-top h-100 left-bar col-12 col-md-4 col-lg-3 col-xl-2">
            <SideBar></SideBar>
          </div>
          <div className="right-profile offset-md-4 offset-lg-3 offset-xl-2 col-12 col-md-8 col-lg-9 col-xl-10 px-md-5">
            <DateFinanciareBanner></DateFinanciareBanner>
            <div className="row">
              <div className="col-12 col-xl-6">
                <EvolutieCifraAfacereChart></EvolutieCifraAfacereChart>
              </div>
              <div className="col-12 col-xl-6">
                <EvolutieProfitChart></EvolutieProfitChart>
              </div>
            </div>
            <div className="row">
              <div className="header col-12">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h6 className="header-pretitle">
                          o privire scurtă asupra lucrărilor firmei
                        </h6>
                        <h1 className="header-title">Segmentare activitate</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-12">
                <TipuriLucrariTable></TipuriLucrariTable>
              </div>
              <div className="col-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="card-header-title">
                          Segmentarea pe domenii a proiectelor realizate de{" "}
                          {companyProfile.dateGenerale.nume}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <SegmentareDomeniiChart></SegmentareDomeniiChart>
                    <SegmentareDomeniiMobileChart></SegmentareDomeniiMobileChart>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="header col-12">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h6 className="header-pretitle">
                          fii remarcabil menționând informațiile din interior
                        </h6>
                        <h1 className="header-title">
                          Proiecte realizate de{" "}
                          {companyProfile.dateGenerale.nume}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row proiecte-rows">
              <div className="col-12 col-lg-3 col-xl h-100">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="card-title text-uppercase text-muted mb-2">
                          Număr de proiecte avute în derulare de{" "}
                          {companyProfile.dateGenerale.nume} și monitorizate de
                          VICTA
                        </h6>
                        <span className="h2 mb-2">
                          {companyProfile.numarTotalProiecte}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="card-title text-uppercase text-muted mb-2">
                          Procentele de lucrări realizate de {" "}
                          {companyProfile.dateGenerale.nume} în funcție de tipul finanțării
                        </h6>
                                                 					
                          {segmentareProiectePerFinantare.map((item) => (
                          <div class="mt-4">                            
                            <h4 class="card-header-title"> Finantare {item.finantare}</h4>
                             <span className="h2 mt-2"><img src={"img/iconite/" + item.finantare + ".svg"} class="img-finance-type"/> {Math.round(item.total * 100/ segmentareProiectePerFinantare.reduce((prev,next)=> prev+next.total, 0)) +"%"} </span>
                          </div>
                          ))} 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-12 col-lg-9 col-xl-9">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="card-header-title">
                          Distribuția pe județe a proiectelor
                        </h4>
                      </div>
                      <div className="col-auto">
                        <h4 className="card-header-title text-muted">
                          Sursă: VICTA.ro
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <SegmentareDomeniiPeJudete></SegmentareDomeniiPeJudete>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="card-header-title">
                          Lista de proiecte {companyProfile.dateGenerale.nume}{" "}
                          monitorizate de VICTA
                        </h4>
                      </div>
                      <div className="col-auto">
                        <h4 className="card-header-title text-muted">
                          Sursă: VICTA.ro
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <ListaProiecte></ListaProiecte>
                  </div>
                </div>
              </div>
            </div>
            {((topuri && topuri.national && topuri.national.length) ||
              (topuri && topuri.regional && topuri.regional.length)) ? (
              <div>
                <div className="row">
                  <div className="header col-12">
                    <div className="container-fluid">
                      <div className="header-body">
                        <div className="row align-items-end">
                          <div className="col">
                            <h6 className="header-pretitle">
                              vezi cum s-a clasat firma pe anul trecut în
                              segmentele cele mai active pentru{" "}
                              {companyProfile.dateGenerale.nume}
                            </h6>
                            <h1 className="header-title">
                              Topuri {lastYear} pentru{" "}
                              {companyProfile.dateGenerale.nume}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row proiecte-rows">
                  <div className="col-12 col-lg-4 col-xl-4 h-100">
                    <div className="card">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h4 className="card-header-title">
                              Succese {companyProfile.dateGenerale.nume} din{" "}
                              {lastYear}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <ArticolePresa></ArticolePresa>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <TopuriCompanie></TopuriCompanie>
                  </div>
                </div>
              </div>
            ) : <div></div>}
            <div className="row">
              <div className="header col-12">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h6 className="header-pretitle">
                          Cu ce firme lucrează{" "}
                          {companyProfile.dateGenerale.nume}
                        </h6>
                        <h1 className="header-title">Networking pe șantiere</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NetworkingFirmeGraph></NetworkingFirmeGraph>
              </div>
            </div>
            <div className="row">
              <div className="header col-12">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h6 className="header-pretitle">
                          începe conversația cu această firmă arătând că știi
                          persoanele din spatele companiei
                        </h6>
                        <h1 className="header-title">
                          Oameni cheie de la {companyProfile.dateGenerale.nume}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 col-xl-4">
                <ListaOameniCheie
                  title={"Administratorii " + companyProfile.dateGenerale.nume}
                  role="Administrator"
                  oameniCheie={administratori}
                ></ListaOameniCheie>
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <ListaOameniCheie
                  title={"Asociații " + companyProfile.dateGenerale.nume}
                  role="Asociat"
                  oameniCheie={asociati}
                ></ListaOameniCheie>
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <InformatiiAdminsitrativeTable></InformatiiAdminsitrativeTable>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <EvolutieNumarAngajatiChart></EvolutieNumarAngajatiChart>
              </div>
            </div>
            {articole && articole.length ? (
              <div>
                <div className="row">
                  <div className="header col-12">
                    <div className="container-fluid">
                      <div className="header-body">
                        <div className="row align-items-end">
                          <div className="col">
                            <h6 className="header-pretitle">
                              creează-ți o conexiune cu această firmă arătându-i
                              că ai citit aceste articole de presă
                            </h6>
                            <h1 className="header-title">
                              Articole în care a fost menționată compania{" "}
                              {companyProfile.dateGenerale.nume}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TendinteNoutatiTable></TendinteNoutatiTable>
                  </div>
                </div>
              </div>
            ) : <div></div>}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <span className="align-middle center">
      <PushSpinner size={50} color="#2b4566" loading={!companyProfileLoaded} />
    </span>
  );
}
