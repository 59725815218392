import React, {
  Component,
  useEffect
} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_ro from "@amcharts/amcharts4-geodata/romaniaLow";
import am4lang_ro from "@amcharts/amcharts4/lang/ro_RO";
import {
  useStoreState
} from "easy-peasy";
import _zipObject from "lodash/zipObject";

const SegmentareDomeniiPeJudete = () => {
  let segmentareActivitate = useStoreState(
    state => state.companyProfile.companyProfile.segmentareActivitatePeJudete
  );

  useEffect(() => {
    if (segmentareActivitate && segmentareActivitate.length) {
      let segmentareDict = _zipObject(segmentareActivitate.map(item => item.judetShortName), segmentareActivitate.map(item => item.total || 0));

      var chart = am4core.create("segmentare-domenii-judete-chart", am4maps.MapChart);

      // Set map definition
      chart.geodata = am4geodata_ro;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      var polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = am4core.color("#f3f3f3");

      polygonTemplate.strokeOpacity = 1;
      polygonTemplate.strokeWidth = 1;
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.stroke = am4core.color("#ffffff");

      // Create hover state and set alternative fill color
      var hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#2b4566");

      // Remove Antarctica
      polygonSeries.exclude = ["AQ"];

      // Add heat rule
      polygonSeries.heatRules.push({
        "property": "fill",
        "target": polygonSeries.mapPolygons.template,
        "min": am4core.color("#9cd919"),
        "max": am4core.color("#005b01")
      });

      // Add expectancy data
      polygonSeries.data = segmentareActivitate.map((item) => ({
        id: "RO-" + item.judetShortName,
        value: item.total
      }));

      console.log(polygonSeries.data);

      // let chart = am4core.create(
      //   "segmentare-domenii-judete-chart",
      //   am4maps.MapChart
      // );
      chart.paddingRight = 20;
      chart.height = 600;
      chart.svgContainer.htmlElement.style.height = 600 + "px";
      chart.language.locale = am4lang_ro;

      // chart.geodata = am4geodata_ro;
      // chart.projection = new am4maps.projections.Miller();
      // var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // polygonSeries.useGeodata = true;
      // polygonSeries.mapPolygons.template.events.on("hit", function (ev) {
      //   chart.zoomToMapObject(ev.target);
      // });

      // let labelSeries = chart.series.push(new am4maps.MapImageSeries());
      // let labelTemplate = labelSeries.mapImages.template.createChild(
      //   am4core.Label
      // );
      // labelTemplate.horizontalCenter = "middle";
      // labelTemplate.verticalCenter = "middle";
      // labelTemplate.fontSize = 14;
      // labelTemplate.nonScaling = true;
      // labelTemplate.interactionsEnabled = false;

      var longitude = {

        "RO-B": 26.40,
        "RO-AB": 23.32,
        "RO-AR": 21.65,
        "RO-AG": 24.67,
        "RO-BC": 26.66,
        "RO-BH": 22.05,
        "RO-BN": 24.42,
        "RO-BT": 26.72,
        "RO-BV": 25.15,
        "RO-BR": 27.52,
        "RO-BZ": 26.58,
        "RO-CL": 26.90,
        "RO-CS": 21.95,
        "RO-CJ": 23.45,
        "RO-CT": 28.11,
        "RO-CV": 25.84,
        "RO-DB": 25.35,
        "RO-DJ": 23.55,
        "RO-GL": 27.59,
        "RO-GR": 25.79,
        "RO-GJ": 23.22,
        "RO-HR": 25.43,
        "RO-HD": 22.76,
        "RO-IL": 26.99,
        "RO-IS": 27.26,
        "RO-IF": 26.03,
        "RO-MM": 23.73,
        "RO-MH": 22.69,
        "RO-MS": 24.39,
        "RO-NT": 26.20,
        "RO-OT": 24.30,
        "RO-PH": 25.82,
        "RO-SM": 22.60,
        "RO-SJ": 22.99,
        "RO-SB": 24.10,
        "RO-SV": 25.65,
        "RO-TR": 25.03,
        "RO-TM": 21.15,
        "RO-TL": 28.50,
        "RO-VS": 27.59,
        "RO-VL": 24.00,
        "RO-VN": 26.77
      };

      var latitude = {

        "RO-B": 44.10,
        "RO-AB": 46.22,
        "RO-AR": 46.33,
        "RO-AG": 45.05,
        "RO-BC": 46.45,
        "RO-BH": 47.05,
        "RO-BN": 47.30,
        "RO-BT": 47.85,
        "RO-BV": 45.80,
        "RO-BR": 45.16,
        "RO-BZ": 45.35,
        "RO-CL": 44.40,
        "RO-CS": 45.24,
        "RO-CJ": 46.82,
        "RO-CT": 44.31,
        "RO-CV": 46.00,
        "RO-DB": 44.96,
        "RO-DJ": 44.20,
        "RO-GL": 45.86,
        "RO-GR": 44.15,
        "RO-GJ": 45.08,
        "RO-HR": 46.59,
        "RO-HD": 45.72,
        "RO-IL": 44.70,
        "RO-IS": 47.30,
        "RO-IF": 44.69,
        "RO-MM": 47.72,
        "RO-MH": 44.58,
        "RO-MS": 46.62,
        "RO-NT": 47.00,
        "RO-OT": 44.40,
        "RO-PH": 45.20,
        "RO-SM": 47.68,
        "RO-SJ": 47.20,
        "RO-SB": 45.85,
        "RO-SV": 47.62,
        "RO-TR": 44.12,
        "RO-TM": 45.76,
        "RO-TL": 45.05,
        "RO-VS": 46.58,
        "RO-VL": 45.20,
        "RO-VN": 45.86

      };

      let labelSeries = chart.series.push(new am4maps.MapImageSeries());
      let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
      // labelTemplate.horizontalCenter = "middle";
      // labelTemplate.verticalCenter = "middle";
      labelTemplate.fontSize = 14;
      labelTemplate.nonScaling = true;
      labelTemplate.interactionsEnabled = false;

      polygonSeries.events.on("inited", function () {
        polygonSeries.mapPolygons.each(function (polygon) {
          let label = labelSeries.mapImages.create();
          let state = polygon.dataItem.dataContext.id.split("-").pop();
          state += " " + (segmentareDict[state] || "0");
          label.latitude = latitude[polygon.dataItem.dataContext.id];
          label.longitude = longitude[polygon.dataItem.dataContext.id];
          label.children.getIndex(0).text = state;
        });
      });

      chart.seriesContainer.resizable = false;
      chart.maxZoomLevel = 3;
      if (window.screen.availWidth > 1000) {
        chart.maxZoomLevel = 1;
      };
      if (window.screen.availWidth < 1000) {
        chart.homeZoomLevel = 3;
      };


      // polygonSeries.heatRules.push({
      //   property: "fill",
      //   target: polygonSeries.mapPolygons.template,
      //   min: am4core.color("#ffffff"),
      //   max: am4core.color("#AAAA00")
      // });

      // chart.data = segmentareActivitate.map((item) => ({
      //   id: "RO-" + item.judetShortName,
      //   name: item.judetShortName,
      //   value: item.total || 0
      // }));

      // chart.validateData();
    }


    // Add label
  }, [segmentareActivitate]);

  return ( <div className="chart"><div id="segmentare-domenii-judete-chart"></div></div>  );
};

export default SegmentareDomeniiPeJudete;