import { thunk, action } from "easy-peasy";
import companyProfileService from "./service";

export default {
  getCompanyProfile: thunk(async (actions, p) => {
    try {
      const companyProfile = await companyProfileService.getCompanyProfile(p);
      actions._setCompanyProfile(companyProfile);
    } catch (err) {
      actions._setCompanyProfileError();
    }
  }),
  _setCompanyProfile: action((state, payload) => {
    state.companyProfile = payload;
    state.companyProfileLoaded = true;
  }),
  _setCompanyProfileError: action(state => {
    state.companyProfileError = true;
  })
};
