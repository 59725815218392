import React from "react";
import PropTypes from "prop-types";

function ListaOameniCheie({ title, role, oameniCheie }) {
  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">{title}</h4>
          </div>
        </div>
      </div>
      {oameniCheie.map(angajat => (
        <div key={angajat.nume} className="card-body">
          <div className="row align-items-center">
            <div className="col-auto">
              <span className="avatar avatar-lg">
                <img
                  src="img/avatar-1.jpg"
                  alt="..."
                  className="avatar-img rounded-circle"
                />
              </span>
            </div>
            <div className="col ml-n2">
              <h4 className="card-title mb-1">
                <span>{angajat.nume}</span>
              </h4>
              <p className="card-text small">{role}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

ListaOameniCheie.propTypes = {
  title: PropTypes.string,
  role: PropTypes.string,
  oameniCheie: PropTypes.array
};

export default ListaOameniCheie;
