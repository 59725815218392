import React, { Component, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ro from "@amcharts/amcharts4/lang/ro_RO";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useStoreState } from "easy-peasy";

const SegmentareDomeniiChart = () => {
  let segmentareActivitate = useStoreState(
    state => state.companyProfile.companyProfile.segmentareActivitate
  );

  useEffect(() => {
    am4core.useTheme(target => {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#061e3e"),
          am4core.color("#251e3e"), 
          am4core.color("#451e3e"), 
          am4core.color("#651e3e"),
          am4core.color("#851e3e"),
          am4core.color("#a4001e"),
          am4core.color("#4f0d19"),
          am4core.color("#3e1e39"),
          am4core.color("#22063e"),
          am4core.color("#061e3e"),
          am4core.color("#251e3e"), 
          am4core.color("#451e3e"), 
          am4core.color("#651e3e"),
          am4core.color("#851e3e"),
          am4core.color("#a4001e"),
          am4core.color("#4f0d19"),
          am4core.color("#3e1e39"),
          am4core.color("#22063e")
        ];
      }
    });

    let chart = am4core.create("segmentare-domenii-chart", am4charts.PieChart);

    // Add data
    chart.data = segmentareActivitate.map(item => ({
      sector: item.domeniu,
      size: item.total
    }));

    // Add label
    chart.innerRadius = am4core.percent(70);
    chart.radius = am4core.percent(100);

    var label = chart.seriesContainer.createChild(am4core.Label);
    label.text = "Segmentarea pe domenii a lucrărilor";    
    label.textAlign = "middle";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.wrap = true;
    label.maxWidth = 120;
    label.fontSize = 20;

    chart.language.locale = am4lang_ro;
    chart.height = 400;
    chart.svgContainer.htmlElement.style.height = 400 + "px";
    chart.numberFormatter.numberFormat = "#";

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "size";
    pieSeries.dataFields.category = "sector";

    //pieSeries.ticks.template.disabled = true;
    //pieSeries.alignLabels = false;
    pieSeries.labels.template.text =
      "{sector} {value.percent.formatNumber('#.')}%";
    pieSeries.labels.template.textAlign = "middle";
    //pieSeries.labels.template.radius = am4core.percent(-20);
    pieSeries.labels.template.fill = am4core.color("#444444");
    //pieSeries.labels.template.maxWidth = 120;
    // pieSeries.labels.template.wrap = true;
    //pieSeries.labels.template.fontSize = 14;      
    pieSeries.labels.template.padding(15,0,15,0);  


    pieSeries.slices.template.stroke = am4core.color("#ffffff");
    pieSeries.slices.template.strokeWidth = 0.4;
    pieSeries.slices.template.strokeOpacity = 1;

  /*
    pieSeries.labels.template.adapter.add("radius", function(radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 5) {
        return 2;
      }
      return radius;
    });



    pieSeries.labels.template.adapter.add("fill", function(color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 5) {
        return am4core.color("#212529");
      }
      return color;
    });

    */

  }, [segmentareActivitate]);


  return (
    <div className="chart">
      <div className="d-none d-xs-none d-sm-none d-md-none d-lg-block">        
          <div id="segmentare-domenii-chart"></div>        
      </div>
    </div>
  );
};

export default SegmentareDomeniiChart;
