import React, { useEffect, useReducer } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4lang_ro from "@amcharts/amcharts4/lang/ro_RO";
import { useStoreState } from "easy-peasy";

const colorMapping = {
  0: "#dca600",
  2: "#7cbc00",
  1: "#009bbc",
  4: "#7cbc00",
  // la astea nu stiu
  3: "#009bbc", // subproiectant,
  5: "#dedede" // chirias
};

const NetworkingFirmeGraph = () => {
  let numeCompanie = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale.nume
  );

  let rolCompanie = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale.functie
  );

  let networkingCompanii = useStoreState(
    state => state.companyProfile.companyProfile.networkingCompanii
  );

  const [legendToggle, setLegendToggle] = useReducer(
    function(state, action) {
      switch (action.type) {
        case "TOGGLE":
          return {
            ...state,
            ...{ [action.data.field]: !state[action.data.field] }
              };
          case "SHOW_ALL":
              return {
                  ...state,
                  ...{ showAll: !state.showAll }
              };
        default:
          return state;
      }
    },
      { ag: true, p: true, b: true, showAll: true }
  );

    let generateChartData = baseData => {
        let filteredToggledData = baseData.filter(
            item =>
                (legendToggle.b && item.pozitie === 0) ||
                (legendToggle.p && (item.pozitie === 1 || item.pozitie === 3)) ||
                (legendToggle.ag && (item.pozitie === 2 || item.pozitie === 4))
        );

        if (!legendToggle.showAll) {
            filteredToggledData = filteredToggledData.filter(
                item => item.totalProiecte > 5
            );
        }

        return [{
            name: numeCompanie, color: colorMapping[rolCompanie],
            children: filteredToggledData.map(item => ({
                name: item.numeCompanie,
                value: item.totalProiecte,
                color: colorMapping[item.pozitie],
                pozitie: item.pozitie
            }))
        }
        ];
    };

  useEffect(() => {
    if (networkingCompanii && networkingCompanii.length) {
      let chart = am4core.create(
        "firme-networking-graph",
        am4plugins_forceDirected.ForceDirectedTree
      );
      chart.width = am4core.percent(100);
      chart.height = 500;
      chart.svgContainer.htmlElement.style.height = 500 + "px";
      chart.language.locale = am4lang_ro;

      var networkSeries = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries()
      );

      chart.data = generateChartData(networkingCompanii);

      networkSeries.dataFields.value = "value";
      networkSeries.dataFields.name = "name";
      networkSeries.dataFields.children = "children";
      networkSeries.dataFields.color = "color";

      networkSeries.nodes.template.label.text = "{name}";
      networkSeries.nodes.template.label.valign = "bottom";
      networkSeries.nodes.template.label.fill = am4core.color("#2b4566");

      networkSeries.links.template.distance = 4;

      //networkSeries.nodes.template.tooltipText = "{name}:{value}";
      networkSeries.nodes.template.tooltipText = "{name} \n {value} proiect(e)";
      networkSeries.nodes.template.fillOpacity = 1;
      networkSeries.manyBodyStrength = -30;
      networkSeries.links.template.strength = 0.1;
      networkSeries.minRadius = am4core.percent(2);
      networkSeries.centerStrength = 0.4;

      networkSeries.nodes.template.label.text = "{name}";
      networkSeries.nodes.template.label.dy = 12;
      networkSeries.nodes.template.label.wrap = false;
      networkSeries.fontSize = 15;
      networkSeries.minRadius = 5;
      networkSeries.maxRadius = 80;
      networkSeries.maxLevels = 2;

      networkSeries.adapter.add("maxRadius", function(maxRadius, target) {
        if (window.screen.width < 1100) {
          return 20;
        }
        return maxRadius;
      });

      networkSeries.adapter.add("minRadius", function(minRadius, target) {
        if (window.screen.width < 1100) {
          return 3;
        }
        return minRadius;
      });
    }
  }, [networkingCompanii, legendToggle]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">
              Networking companii - Cu ce firme lucrează {numeCompanie}
            </h4>
          </div>
          <div className="col-auto">
            <span className="text-muted">Sursă / VICTA</span>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row legenda-networking pb-2 mb-5">
          <div
            className="col-12 col-md-2 float-right"
            onClick={() =>
              setLegendToggle({ type: "TOGGLE", data: { field: "p" } })
            }
          >
            <span className={legendToggle.p ? "p-legend" : "inactive"}></span>
            Proiectant și subproiectant
          </div>
          <div
            className="col-12 col-md-2 float-right"
            onClick={() =>
              setLegendToggle({ type: "TOGGLE", data: { field: "ag" } })
            }
          >
            <span className={legendToggle.ag ? "ag-legend" : "inactive"}></span>
            Antreprenor general
          </div>
          <div
            className="col-12 col-md-2 float-right"
            onClick={() =>
              setLegendToggle({ type: "TOGGLE", data: { field: "b" } })
            }
          >
            <span className={legendToggle.b ? "b-legend" : "inactive"}></span>
            Beneficiar
          </div>
                  <div
                      className="col-12 col-md-4 float-right"
                      onClick={() =>
                          setLegendToggle({ type: "SHOW_ALL" })
                      }
                  >
                      <span className={legendToggle.showAll ? "inactive" : "show-all"}></span>
                      Parteneri recurenți (+5 proiecte în comun)
                  </div>
        </div>
        <div className="chart">
          <div id="firme-networking-graph"></div>
        </div>
      </div>
    </div>
  );
};

export default NetworkingFirmeGraph;
