import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { toDecimalFractions } from "../../../utils/numberFormatter";
import _some from "lodash/some";

function DateFinanciareBanner() {
  const bilant = useStoreState(
    state => state.companyProfile.companyProfile.bilantRecent
  );

  const [
    afiseazaCrestereProcentuala,
    setAfiseazaCrestereProcentuala
  ] = useState(false);

  const dateGenerale = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale
  );

  const bilanturi = useStoreState(
    state => state.companyProfile.companyProfile.bilanturi
  );

  useEffect(() => {
    if (bilanturi && bilanturi.length == 2) {
      if (_some(bilanturi, item => item.cui == null)) {
        setAfiseazaCrestereProcentuala(false);
      }
    } else {
      setAfiseazaCrestereProcentuala(true);
    }
  }, [bilanturi]);

  return (
    bilant && (
      <div>
        <div className="row">
          <div className="header col-12">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle">
                      sparge gheața în conversație folosind indicatorii
                      financiari oferiți de termene.ro
                    </h6>
                    <h1 className="header-title">
                      Date financiare {dateGenerale.nume}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ca-3p">
          <div className="col-12 col-lg-6 col-xl h-100">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="card-title text-uppercase text-muted mb-2">
                      Cifră afaceri {bilant.an} RON
                    </h6>
                    <span className="h2 mb-2">
                      {toDecimalFractions(bilant.cifraAfaceri)}
                    </span>
                    {afiseazaCrestereProcentuala &&
                    bilant.cifraAfaceriCrestereProcentuala >= 0 ? (
                      <span
                        className={
                          bilant.cifraAfaceriCrestereProcentuala >= 0
                            ? "badge badge-soft-success mt-n1"
                            : "badge badge-soft-danger mt-n1"
                        }
                      >
                        {bilant.cifraAfaceriCrestereProcentuala}% față de{" "}
                        {bilant.an - 1}
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl h-100">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="card-title text-uppercase text-muted mb-2">
                      {bilant.profit > 0 ? "Profit" : "Pierdere"} {bilant.an}{" "}
                      RON
                    </h6>
                    <span className="h2 mb-2">
                      {toDecimalFractions(bilant.profit)}
                    </span>
                    {bilant.profitCrestereProcentuala && (
                      <span
                        className={
                          bilant.profitCrestereProcentuala > 0
                            ? "badge badge-soft-success mt-n1"
                            : "badge badge-soft-danger mt-n1"
                        }
                      >
                        {bilant.profitCrestereProcentuala}% față de{" "}
                        {bilant.an - 1}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl h-100">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="card-title text-uppercase text-muted mb-2">
                      Productivitate RON / Angajat
                    </h6>
                    <span className="h2 mb-2">
                      {toDecimalFractions(bilant.productivitatePerAngajat)}{" "}
                      <br />
                    </span>
                    {bilant.productivitatePerAngajatCrestereProcentuala && (
                      <span
                        className={
                          bilant.productivitatePerAngajatCrestereProcentuala > 0
                            ? "badge badge-soft-success mt-n1"
                            : "badge badge-soft-danger mt-n1"
                        }
                      >
                        {bilant.productivitatePerAngajatCrestereProcentuala}%
                        față de {bilant.an - 1}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl h-100">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="card-title text-uppercase text-muted mb-2">
                      Profitabilitate
                    </h6>
                    <div className="row align-items-center no-gutters">
                      <div className="col-12">
                        <span className="h2 mr-2 mb-4">
                          {bilant.profitabilitate} %
                        </span>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-sm">
                          <div
                            className={
                              bilant.profitabilitate > 0
                                ? "progress-bar positive"
                                : "progress-bar negative"
                            }
                            role="progressbar"
                            style={{
                              width: Math.abs(bilant.profitabilitate) + "%"
                            }}
                            aria-valuenow={bilant.profitabilitate}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DateFinanciareBanner;
