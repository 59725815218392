import format from "date-fns/format";
import roLocale from "date-fns/locale/ro";

export const toMonthAndYear = date => formatDate(date, "MMMM yyyy");
export const toDayMonthAndYear = date => formatDate(date, "dd MMMM yyyy");
export const toRomanianDate = date => formatDate(date, "dd.MM.yyyy");
export const toCardExpiryDate = date => formatDate(date, "MM/yyyy");

function formatDate(date, dateFormat) {
  if (!date) {
    return null;
  }

  return format(new Date(date), dateFormat, { locale: roLocale });
}
