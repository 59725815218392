export default {
  companyProfile: {
    dateGenerale: {
      activaFiscal: null,
      adresa: null,
      adresaAnaf: null,
      codCaen: null,
      codInmatriculare: null,
      cui: null,
      dataInregistrarii: null,
      datoriiAnaf: null,
      domeniuActivitate: null,
      emails: null,
      judet: null,
      localitate: null,
      nume: null,
      platitorTva: null,
      telefoane: null,
      vechime: null,
      web: null,
      facebookUrl: null,
      linkedInUrl: null,
      oras : null
    },
    bilanturi: [],
    bilantRecent: null,
    segmentareActivitate: [],
    articole: [],
    topStatistici: {
      national: null,
      regional: null
    },
    seoCompetitors: [],
    oameniCheie : []
  },
  companyProfileError : false,
  companyProfileLoaded : false
};
