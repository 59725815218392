import React from "react";
import { useStoreState } from "easy-peasy";
import { toRomanianDate } from "../../../utils/dateFormatter";
import _startOfWeek from "date-fns/startOfWeek";
import _addWeeks from "date-fns/addWeeks";

export default function SideBar() {
  const dateGenerale = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale
  );

  const lastUpdatedDate = _startOfWeek(_addWeeks(new Date(), -1));

  return (
    <div className="container-fluid">
      <div className="row date-firma">
        <div className="header col-12">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h6 className="header-pretitle">actualizat {toRomanianDate(lastUpdatedDate)}</h6>
                  <h1 className="header-title">{dateGenerale.nume}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col-12 date-firma">
          <div className="col">
            <h4>
              <i className="fa fa-id-card-o" aria-hidden="true"></i> Date firmă
            </h4>
            <h6>CUI: {dateGenerale.cui} </h6>
            <h6>Nr. înregistrare: {dateGenerale.codInmatriculare} </h6>
            <h6>
              Data înființări: {toRomanianDate(dateGenerale.dataInregistrarii)}
            </h6>
            {dateGenerale.codCaen && dateGenerale.codCaen > 0 ? <h6>Cod CAEN principal: {dateGenerale.codCaen} </h6> : <div></div>}
            {dateGenerale.domeniuActivitate ? <h6>Obiect de activitate: {dateGenerale.domeniuActivitate}</h6> : <div></div>}

            <h4>
              <i className="fa fa fa-calendar" aria-hidden="true"></i>{" "}
              Experiență firmă
            </h4>
            <h2>
              <b>{dateGenerale.vechime}</b>
            </h2>

            <h4>
              <i className="fa fa-handshake-o" aria-hidden="true"></i> Date
              contact
            </h4>

            {dateGenerale.adresa && dateGenerale.adresa.length > 4 ? (
              <h6>
                <b>Adresă:</b> {dateGenerale.adresa}, {dateGenerale.oras}, {dateGenerale.judet}
              </h6>
            ) : <div></div>}

            {dateGenerale.web && dateGenerale.web.length > 4 && (
              <h6>
                <b>Site:</b>  {dateGenerale.web.replace(/^https?\:\/\//i, "").replace(/,/g, ', ')}
              </h6>
            )}

            {dateGenerale.emails && dateGenerale.emails.length > 4 && (
              <h6>
                <b>Email:</b> {dateGenerale.emails.replace(/,/g, ', ')}
              </h6>
            )}

            {dateGenerale.telefoane && dateGenerale.telefoane.length > 4 && (
              <h6>
                <b>Telefon:</b>  {dateGenerale.telefoane.replace(/,/g, ', ')}
              </h6>
            )}
            {(dateGenerale.facebookUrl || dateGenerale.linkedInUrl) && (
              <h4>
                <i className="fas fa-share-alt-square"></i> Conturi de social media
              </h4>
            )}
            {dateGenerale.facebookUrl && (
              <h6>
                <b>Facebook:</b>{" "}
                <a href={dateGenerale.facebookUrl} className="text-muted">
                  link profil
                </a>{" "}
              </h6>
            )}
            {dateGenerale.linkedInUrl && (
              <h6>
                <b>LinkedIn:</b>{" "}
                <a href={dateGenerale.linkedInUrl} className="text-muted">
                  link profil
                </a>{" "}
              </h6>
            )}
          </div>
          <div className="col-12 termene mt-5">
            <p> PROFIL REALIZAT ÎMPREUNĂ CU </p>
            <a href="https://termene.ro/" target="_blank"><img src="/img/termene.svg"></img></a>
          </div>
        </div>
      </div>
    </div>
  );
}
