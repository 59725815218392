import React, { PureComponent, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ro from "@amcharts/amcharts4/lang/ro_RO";
import { useStoreState } from "easy-peasy";
import _orderBy from "lodash/orderBy";

const EvolutieProfitChart = () => {
  let bilanturi = useStoreState(
    state => state.companyProfile.companyProfile.bilanturi
  );

  useEffect(() => {
    am4core.useTheme(target => {
      if (target instanceof am4core.ColorSet) {
        target.list = [am4core.color("#9cd919")];
      }
    });
    // Create chart instance
    var chart = am4core.create("evolutie-profit-chart", am4charts.XYChart);
    chart.paddingRight = 20;
    chart.height = 300;
    chart.svgContainer.htmlElement.style.height = 300 + "px";
    chart.language.locale = am4lang_ro;

    // Add data
    chart.data = _orderBy(
      bilanturi.map(bilant => ({
        year: bilant.an + "",
        value: bilant.profitSauPierdereNet
      })),
      ["year"],
      ["asc"]
    );

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.baseValue = 0;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.tooltip.disabled = true;

    var range1 = valueAxis.axisRanges.create();
    range1.value = 0;
    range1.grid.stroke = am4core.color("#95aac9");
    range1.grid.strokeWidth = 0.1;
    range1.grid.strokeOpacity = 1;

    

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";
    series.strokeWidth = 2;
    series.tensionX = 0.77;

    let range = valueAxis.createSeriesRange(series);
    range.value = -1;
    range.endValue = -999999999;
    range.contents.stroke = am4core.color("#f00");
    range.contents.fill = am4core.color("#f00");
    range.contents.fillOpacity = 0.1;

    // bullet is added because we add tooltip to a bullet for it to change color
    var bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = "{valueY}";

    bullet.adapter.add("fill", function(fill, target) {
      if (target.dataItem.valueY < 0) {
        return am4core.color("#FF0000");
      }
      return fill;
    });

    chart.cursor = new am4charts.XYCursor();
  }, [bilanturi]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">
              Evoluția pe ultimii 10 ani al profitului
            </h4>
          </div>
          <div className="col-auto"><h4 className="card-header-title text-muted">Sursă: termene.ro</h4></div>
        </div>
      </div>
      <div className="card-body">
        <div className="chart">
          <div id="evolutie-profit-chart"></div>
        </div>
      </div>
    </div>
  );
};

export default EvolutieProfitChart;
