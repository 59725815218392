import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { toRomanianDate } from "../../../utils/dateFormatter";
import _take from "lodash/take";

export default function ListaProiecteTable() {
  let projects = useStoreState(
    state => state.companyProfile.companyProfile.proiecteMonitorizate
  );

  let [showCount, setShowCount] = useState(10);
  let [projectsToDisplay, setProjectsToDisplay] = useState([]);

  useEffect(() => {
    if (projects && projects.length) {
      setProjectsToDisplay(_take(projects, showCount));
    }
  }, [projects, showCount]);

  return (
    <div
      className="table-responsive"
      data-toggle="lists"
      data-options='{"valueNames": ["tables-status", "tables-role", "tables-date"]}'
    >
      <div className="d-none d-xs-none d-sm-none d-md-none d-lg-block">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <a href="#" className="text-muted">
                  Proiect
                </a>
              </th>
              <th scope="col">
                <a
                  href="#"
                  className="text-muted sort"
                  data-sort="tables-status"
                >
                  Stadiu{" "}
                </a>
              </th>
              <th scope="col">
                <a href="#" className="text-muted sort" data-sort="tables-role">
                  Rol
                </a>
              </th>
              <th scope="col">
                <a href="#" className="text-muted">
                  Județ
                </a>
              </th>
              <th scope="col">
                <a href="#" className="text-muted sort" data-sort="tables-date">
                  Dată
                </a>
              </th>
              <th scope="col">
                <a href="#" className="text-muted">
                  Link Victa
                </a>
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {projectsToDisplay && projectsToDisplay.length ?
              projectsToDisplay.map(item => (
                <tr key={item.projectNumber}>
                  <th scope="row">{item.numeProiect}</th>
                  <td>{item.descriereStadiuProiect}</td>
                  <td>{item.descriereRolCompanie}</td>
                  <td>{item.judet}</td>
                  <td>{toRomanianDate(item.dataUltimeiActualizari)}</td>
                  <td>
                    <a
                      href={`https://victa.ro/app/projects/details/${item.projectNumber}`}
                      className="text-muted"
                      target="_blank"
                    >
                      Detalii
                    </a>
                  </td>
                </tr>
              )) : <div></div>}
          </tbody>
        </table>

        {projects && projects.length > showCount ? (
          <div className="col-2 col-lg-3 col-xl-2 float-right">
            <button
              className="btn btn-primary d-block d-md-inline-block lift float-right"
              onClick={() => setShowCount(showCount + 10)}
            >
              Încarcă încă 10 șantiere
            </button>
          </div>
        ) : <div></div>}
      </div>
      <div className="d-xs-block d-sm-block d-md-block d-lg-none d-block">
        <div className="card-body">
          {projectsToDisplay ?
            projectsToDisplay.map(item => (
              <div className="row" key={item.projectNumber}>
                <div className="col-12 pt-3 font-weight-bold">
                  {item.numeProiect}
                </div>
                <div className="col-12 pt-1">
                  Județ: {item.judet} | Stadiu: {item.descriereStadiuProiect} |
                  Rol: {item.descriereRolCompanie} | Dată:{" "}
                  {toRomanianDate(item.dataUltimeiActualizari)}
                </div>
                <div className="col-12 pt-1 font-weight-bold">
                  <a
                    href={`https://victa.ro/app/projects/details/${item.projectNumber}`}
                    className="text-muted"
                    target="_blank"
                  >
                    Detalii proiect pe VICTA{" "}
                  </a>
                </div>
              </div>
            )) : <div></div>}
          {projects && projects.length > showCount && (
            <div className="col-12 float-left pt-5 pl-0">
              <button
                className="btn btn-primary d-block d-md-inline-block lift float-left"
                onClick={() => setShowCount(showCount + 10)}
              >
                Încarcă încă 10 șantiere
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
