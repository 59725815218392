import React from "react";
import { Route } from "react-router";
import { CompanyDetails } from "./CompanyDetails/CompanyDetails";
import ApiAuthorizationRoutes from "./api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";

export default function Router() {
  return (
    <>
      <Route path="/Profil.aspx" component={CompanyDetails} />
      <Route
        path={ApplicationPaths.ApiAuthorizationPrefix}
        component={ApiAuthorizationRoutes}
      />
    </>
  );
}
