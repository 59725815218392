import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import IconRepresentation from "./TipuriLucrariTable/IconRepresentation";

export default function TipuriLucrariTable() {
	let segmentareActivitate = useStoreState((state) => state.companyProfile.companyProfile.segmentareActivitate);

//	let segmentareProiectePerFinantare = useStoreState((state) => state.companyProfile.companyProfile.proiecteDupaFinantare);

	let [totalProiecte, setTotalProiecte] = useState(0);

	useEffect(() => {
		let value = segmentareActivitate && segmentareActivitate.length ? segmentareActivitate.map((item) => item.total).reduce((acc, currentValue) => acc + currentValue) : 0;

		setTotalProiecte(value);
	}, [segmentareActivitate]);

	return (
		<div className="card">
			<div className="card-header">
				<div className="row align-items-center">
					<div className="col">
						<h4 className="card-header-title">Tipuri de lucrări</h4>
					</div>
					{/* 
          @Cata : Proiecte dupa finantare !!! astea sunt numere de proiecte, nu procente
          {segmentareProiectePerFinantare.map((item) => (
						<div className="col">
              <span className="badge badge-soft-success">{item.finantare} {item.total}</span>
						</div>
					))} */}

					<div className="col-auto">
						<h4 className="card-header-title text-muted">Sursă: VICTA.ro</h4>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="d-none d-xs-none d-sm-none d-md-none d-lg-block">
					<div className="table-responsive" data-toggle="lists" data-options='{"valueNames": ["tables-surface", "tables-number"]}'>
						<table className="table tipuri-lucrari">
							<thead>
								<tr>
									<th scope="col">
										<a href="#" className="text-muted">
											Domeniu
										</a>
									</th>
									<th scope="col">
										<a href="#" className="text-muted sort" data-sort="tables-surface">
											Suprafață m<sup>2</sup>
										</a>
									</th>
									<th scope="col">
										<a href="#" className="text-muted sort" data-sort="tables-number">
											Număr
										</a>
									</th>
									<th scope="col">
										<a href="#" className="text-muted">
											{" "}
											Reprezentare grafică
										</a>
									</th>
								</tr>
							</thead>
							<tbody className="list">
								{segmentareActivitate.map((item, index) => (
									<tr key={index}>
										<th scope="row" className="tables-domain">
											{item.domeniu}
										</th>
										<td className="tables-surface">{item.suprafata || "-"} </td>
										<td className="tables-number">{item.total}</td>
										<td className="tables-icon">
											<IconRepresentation domeniu={item.domeniu} totalDomeniu={item.total} totalProiecte={totalProiecte}></IconRepresentation>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="d-xs-block d-sm-block d-md-block d-lg-none d-block">
					{segmentareActivitate.map((item, index) => (
						<div key={index} className="row tipuri-lucrari pb-4">
							<h3 className="col-12 pt-3 font-weight-bold">{item.domeniu}</h3>
							<div className="col-12 pt-1">
								{item.total} șantiere | Suprafață: {item.suprafata} m²
							</div>
							<div className="col-12 pt-1 tables-icon">
								<IconRepresentation domeniu={item.domeniu} totalDomeniu={item.total} totalProiecte={totalProiecte}></IconRepresentation>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
