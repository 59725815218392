import React from "react";
import PropTypes from "prop-types";

function IconRepresentation({ domeniu, totalDomeniu, totalProiecte }) {
  const iconMatchingTable = {
    "Birouri/Comercial": "img/iconite/birouri.svg",
    Utilitati: "img/iconite/utilitati.svg",
    Infrastructura: "img/iconite/infrastructura.svg",
    Retail: "img/iconite/retail.svg",
    Industrial: "img/iconite/industrial.svg",
    "Medical & Stiintific": "img/iconite/medical.svg",
    "Constructii sociale si Comunitate": "img/iconite/comunitate.svg",
    "Hotel & Relaxare": "img/iconite/hotel.svg",
    "Cladiri rezidentiale private": "img/iconite/rezidential.svg"
  };

  let total = totalProiecte
    ? Math.ceil(
        ((totalProiecte < 10 ? 1.0 : 10.0) * totalDomeniu) / totalProiecte
      )
    : 0;

  let url = iconMatchingTable[domeniu];

  let icons = Array.from(Array(total)).map((item, index) => (
    <img key={"iconRep" + domeniu + index} src={url}></img>
  ));

  return <>{icons}</>;
}

IconRepresentation.propTypes = {
  domeniu: PropTypes.string,
  totalDomeniu: PropTypes.number,
  totalProiecte: PropTypes.number
};

export default IconRepresentation;
