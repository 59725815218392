import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { computeBadge } from "../topuri/statisticsService";

export default function ArticolePresa() {
  let topuri = useStoreState(
    state => state.companyProfile.companyProfile.topStatistici
  );

  let dateGenerale = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale
  );

  let [topuriDeAfisat, setTopuriDeAfisat] = useState([]);

  useEffect(() => {
    if (topuri && topuri.national) {
      let _topuriNational = topuri.national
        .filter(item => item.pozitie <= 100)
        .map(item => ({
          ...item,
          topRegional: false,
          badge: computeBadge(item, false),
          descrierePozitieClasament:
            item.pozitie == 1
              ? "LOCUL 1"
              : item.pozitie <= 10
              ? "TOP 10"
              : "TOP 100"
        }));
      let _topuriRegional = topuri.regional
        ? topuri.regional
            .filter(item => item.pozitie <= 100)
            .map(item => ({
              ...item,
              topRegional: true,
              badge: computeBadge(item, true),
              descrierePozitieClasament:
                item.pozitie == 1
                  ? "LOCUL 1"
                  : item.pozitie <= 10
                  ? "TOP 10"
                  : "TOP 100"
            }))
        : [];
      let _topuri = [..._topuriNational, ..._topuriRegional];

      setTopuriDeAfisat(_topuri);
    }
  }, [topuri]);

  return (
    <ul className="list-group list-group-lg list-group-flush list my-n4">
      {topuriDeAfisat.map((item, index) => (
        <li key={index} className="list-group-item px-0">
          <div className="row align-items-center">
            <div className="col-auto col-md-5 badgeuri">
              <img
                src={item.badge}
                alt="..."
                className="avatar-img rounded w-100"
              />
            </div>
            <div className="col col-md-7 ml-n2">
              <h4 className="card-title mb-1 name">
                {`${item.descrierePozitieClasament} / ${item.contactPositionDescription} ${item.domeniu}`}
              </h4>

              <p className="card-text small text-muted text-uppercase">
                Nivel:{" "}
                {item.topRegional
                  ? "județul " + dateGenerale.judet
                  : "național"}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
