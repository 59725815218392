import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";

const TopuriCompanie = () => {
  let topuri = useStoreState(
    state => state.companyProfile.companyProfile.topStatistici
  );

  let dateGenerale = useStoreState(
    state => state.companyProfile.companyProfile.dateGenerale
  );

  let [topuriToDisplay, setTopuriToDisplay] = useState([]);

  let [topRegional, setTopRegional] = useState(false);

  let lastYear = new Date().getFullYear() - 1;

  useEffect(() => {
    if (topuri) {
      setTopuriToDisplay(topRegional ? topuri.regional : topuri.national);
    }
  }, [topuri, topRegional]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">
              Topuri din {lastYear} pentru {dateGenerale.nume}
            </h4>
          </div>
          {topuri && topuri.regional ? (
            <div className="col-auto">
              <div className="custom-control custom-switch float-right">
                <input
                  value={topRegional}
                  onChange={e => setTopRegional(!topRegional)}
                  type="checkbox"
                  className="custom-control-input"
                  id="cardToggle"
                  data-toggle="chart"
                  data-target="#conversionsChart"
                  data-trigger="change"
                  data-action="add"
                  data-dataset="1"
                ></input>
                <label
                  className="custom-control-label"
                  htmlFor="cardToggle"
                ></label>
              </div>
              <span className="text-muted mr-3 float-right">
                Nivel național / județean:
              </span>
            </div>
          ) : <div></div>}
        </div>
      </div>
      <div className="card-body topuri">
        <div className="row ca-3p">
          {topuriToDisplay.map((item, index) => (
            <div key={index} className="col-12 col-lg-6 col-xl-4 mt-4">
              <span className="h2 mb-0">Locul {item.pozitie}</span>
              <span className="badge badge-soft-success mt-n1 mb-3">
                {item.contactPositionDescription}i de <br /> {item.domeniu}
              </span>
              <h6 className="card-title">
                Top {item.contactPositionDescription}i
              </h6>
              <h6 className="card-title">
                la nivelul {topRegional ? "județului " + dateGenerale.judet : "național"}
              </h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopuriCompanie;
