import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";

export default function InformatiiAdminsitrativeTable() {
  let firmeConexe = useStoreState(
    state => state.companyProfile.companyProfile.firmeConexe
  );

  return firmeConexe && firmeConexe.length ? (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">
              {firmeConexe.length} firme conexe
            </h4>
          </div>
          <div className="col-auto">
            <h4 className="card-header-title text-muted">Sursă: termene.ro</h4>
          </div>
        </div>
      </div>
      <div className="d-none d-xs-none d-sm-none d-md-none d-lg-block">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <a href="#" className="text-muted">
                      Numele firmei
                    </a>
                  </th>
                  <th scope="col">
                    <a href="#" className="text-muted">
                      Județ
                    </a>
                  </th>
                  <th scope="col">
                    <a href="#" className="text-muted">
                      Localitate
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody className="list">
                {firmeConexe.map(item => (
                  <tr key={item.cuiFirma}>
                    <th>{item.numeFirma}</th>
                    <td>{item.judet}</td>
                    <td>{item.localitate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-xs-block d-sm-block d-md-block d-lg-none d-block">
        <div className="card-body">
          {firmeConexe.map(item => (
            <div key={item.cuiFirma} className="row">
              <div className="col-12 pt-3 font-weight-bold">
                {item.numeFirma}
              </div>
              <div className="col-12 pt-1">
                {item.judet} | {item.localitate}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
