import authService from "../../components/api-authorization/AuthorizeService";

async function getCompanyProfile(p) {
  const token = await authService.getAccessToken();
  const response = await fetch(`/api/companyProfile?p=${p}`, {
    headers: !token ? {} : { Authorization: `Bearer ${token}` }
  });
  return await response.json();
}

export default {
  getCompanyProfile
};
