import React, { Component, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ro from "@amcharts/amcharts4/lang/ro_RO";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useStoreState } from "easy-peasy";
import _maxBy from "lodash/maxBy";
import _sumBy from "lodash/sumBy";

const SegmentareDomeniiChart = () => {
  let segmentareActivitate = useStoreState(
    state => state.companyProfile.companyProfile.segmentareActivitate
  );

  useEffect(() => {
    if (segmentareActivitate && segmentareActivitate.length) {
      am4core.useTheme(am4themes_animated);

      var chart = am4core.create(
        "segmentare-domenii-chart-mobile",
        am4charts.XYChart
      );

      // Add data
      // chart.data = segmentareActivitate.map(item => ({
      //  sector: item.domeniu,
      //  size: item.total
      // }));

      let maxDomeniu = _maxBy(segmentareActivitate, "total");
      let totalDomeniu = _sumBy(segmentareActivitate, "total");

      let data = {
        domenii: "Segmentare domenii"
      };

      segmentareActivitate.map(item => {
        data[item.domeniu] = (item.total * 100) / totalDomeniu;
      });

      chart.data = [data];

      chart.height = 700;
      chart.svgContainer.htmlElement.style.height = 700 + "px";

      chart.numberFormatter.numberFormat = "#.";

      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "domenii";
      categoryAxis.renderer.grid.template.location = 0;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = false;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.min = 0;
        valueAxis.max = 100;

      //   function createSeries(item, name) {
      //     var series = chart.series.push(new am4charts.ColumnSeries());
      //     series.dataFields.valueY = item.domeniu;
      //     series.dataFields.categoryX = "domenii";
      //     series.sequencedInterpolation = true;
      //     series.stacked = true;
      //     series.name = item.domeniu;

      //     series.columns.template.width = am4core.percent(60);




      //     return series;
      //   }

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "domenii";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(100);
        series.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{valueY}%";

          var bullet = series.bullets.push(new am4charts.LabelBullet());
          bullet.interactionsEnabled = false;
          bullet.label.text = "[bold]{name}[/][font-size:14px] {valueY}%";
          bullet.label.fill = am4core.color("#ffffff");
          bullet.locationY = 0.5;
          bullet.fillOpacity = 1;

          bullet.adapter.add("fillOpacity", function(fillOpacity, target) {
            if (series.dataFields.valueY < 20 ) {
              return 0;
            }
            return fillOpacity;
          });

        return series;
      }

      segmentareActivitate.map(item => {
        createSeries(item.domeniu, item.domeniu);
      });

      chart.legend = new am4charts.Legend();
    }
  }, [segmentareActivitate]);

  return (
    <div className="chart">
      <div className="d-xs-block d-sm-block d-md-block d-lg-none d-block">
        <div id="segmentare-domenii-chart-mobile"></div>
      </div>
    </div>
  );
};

export default SegmentareDomeniiChart;
