import React from "react";
import { useStoreState } from "easy-peasy";
import { toRomanianDate } from "../../../utils/dateFormatter";

export default function TendinteNoutatiTable() {
  let articole = useStoreState(
    state => state.companyProfile.companyProfile.articole
  );

  return articole && articole.length ? (
    <div className="card">
      <div className="card-header">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="card-header-title">Tendințe și noutăți</h4>
          </div>
          <div className="col-auto">
            <h4 className="card-header-title text-muted">
              Sursă / ConstructFocus
            </h4>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <tbody className="list">
              {articole.map((item, index) => (
                <tr key={index}>
                  <th>
                    {item.title.rendered}
                    <br />> {toRomanianDate(item.modified)} |{" "}
                    <a href={item.link} className="text-muted" target="_blank">
                      Citește mai mult
                    </a>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ): <div></div>;
}
